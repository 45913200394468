import React from 'react';
import { StyledHome } from './styles';
import { Services, Testimonials } from 'views';
import { BriefHistory, Hero } from 'components';

const Home = () => {
  return (
    <StyledHome>
      <Hero />
      <BriefHistory />
      <Services />
      <Testimonials />
    </StyledHome>
  );
};

export default Home;
