import React from "react";
import { StyledServiceBlock } from "./styles";
import { Button } from "components";
import { booking } from "../../helpers";

const ServiceBlock = ({
	id,
	color,
	title,
	image,
	description,
	imagePosition,
	showButton = true,
}) => {
	return (
		<StyledServiceBlock
			className={color}
			$bgimage={image}
			$imagePosition={imagePosition}
			id={id}
		>
			<div className="image" />
			<div className="info">
				<h1>{title}</h1>
				{description.map((paragraph, index) => (
					<p key={index}>{paragraph}</p>
				))}
				{showButton && (
					<Button text="Book Now" className="book-now-btn" onClick={booking} />
				)}
			</div>
		</StyledServiceBlock>
	);
};

export default ServiceBlock;
