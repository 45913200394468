import React from "react";
import { StyledServicesPage } from "./styles";
import {
	servicesChairConfig,
	servicesConfig,
	servicesAboutConfig,
} from "configs/servicesConfig";
import { ServiceBlock } from "components";

const ServicesPage = () => {
	return (
		<StyledServicesPage>
			<div
				id="top"
				className="chair-background-container"
				style={{
					backgroundSize: "cover",
					background: `linear-gradient(0deg, rgb(238 243 236 / 75%) 0%, rgba(255, 255, 255, 0.75) 100%), url(${servicesChairConfig.bgImage}) no-repeat center center/cover`,
				}}
			>
				<div className="chair-container">
					<div className="what-is-chair">
						<h1>{servicesChairConfig.whatIsChair.title}</h1>
						{servicesChairConfig.whatIsChair.description.map(
							(paragraph, index) => (
								<p key={index}>{paragraph}</p>
							)
						)}
					</div>
					<div className="chair-info">
						<div className="contra-indicators">
							<h1>{servicesChairConfig.contraIndicators.title}</h1>
							<p>{servicesChairConfig.contraIndicators.description}</p>
							<ul>
								{servicesChairConfig.contraIndicators.points.map(
									(point, index) => (
										<li key={index}>{point}</li>
									)
								)}
							</ul>
						</div>
						<div className="benefits">
							<h1>{servicesChairConfig.benefits.title}</h1>
							<ul>
								{servicesChairConfig.benefits.points.map((point, index) => (
									<li key={index}>{point}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="services-container">
				{servicesConfig.map((service) => (
					<ServiceBlock key={service.number} {...service} />
				))}
			</div>

			<div className="about-container" id="who-we-are">
				<ServiceBlock {...servicesAboutConfig} showButton={false} />
			</div>
		</StyledServicesPage>
	);
};

export default ServicesPage;
