export default [
	{
		id: 1,
		text: "From a young girl I suffered from the most debilitating period pain. I would have to skip school and university lectures as I would be completely bedridden. The chair significantly helped with the pain and heaviness of my flow, allowing me to go through an entire period without having to take a single pain killer.",
		author: "Kate",
	},
	{
		id: 2,
		text: "After struggling with debilitating menstrual cramps, and a long, heavy cycle my whole adult life due to my PCOS; I turned to a Mirena as a potential treatment. After insertion I was in pain daily, taking painkillers that made me feel disoriented just to make it through the day. After finding out about pelvic floor strengthening as a potential menstrual cramp therapy, I underwent a full 6 sessions. And my pain was gone by the fourth. I've never felt so relieved, and it drove me to want to share the freedom of feeling well with everyone. Especially women in a similar position to me.",
		author: "Alanah",
	},
	{
		id: 3,
		text: "After having my second child I suffered from a prolapsed uterus for months, I thought my body would never recover. It was uncomfortable and embarrassing so when I heard about the chair just after my third child I couldn’t book an appointment fast enough. It truly was the fastest postpartum recovery I experienced, in just a few sessions I was feeling sexier and more confident than ever.",
		author: "Meg",
	},
	{
		id: 4,
		text: "My bladder muscles are drastically stronger. I can even feel when I do kegel exercises myself, that my muscles are stronger.",
		author: "Gesonde Self Client",
	},
	{
		id: 5,
		text: "I had to get up 100 times a night to go to the bathroom and never emptied my bladder properly during the day. But from the first session, I get up less during the night and I can feel that my bladder just gets better and better.",
		author: "Gesonde Self Client",
	},
	{
		id: 6,
		text: "I'm not one to initiate sex, but after only my first session on the chair I couldn't help myself anymore and had to wake my husband up with 'breakfast' in bed.",
		author: "Gesonde Self Client",
	},
	{
		id: 7,
		text: "I didn't expect this at all. I came to sit on the chair to strengthen my bladder muscles, but then for the first time in my life I didn't have a painful period.",
		author: "Gesonde Self Client",
	},
];
