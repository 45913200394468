import React from "react";
import { StyledServiceBubble } from "./styles";
import { Button } from "components";
import { Link } from "react-router-dom";

const ServiceBubble = ({
	id,
	color,
	number,
	title,
	explaination,
	className,
	onMouseEnter,
	onMouseLeave,
	animation,
}) => {
	return (
		<StyledServiceBubble
			className={className}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			animation={animation}
		>
			<div className={`service-bubble ${color}`}>
				<div className="bubble-heading mb-2">
					<h1 className="number">{number}</h1>
					<h1 className="title">{title}</h1>
				</div>
				<div className={`explaination-container ${color}`}>
					<p className="explaination">{explaination}</p>
					<Link to={`/services#${id}`}>
						<Button className={"learn-more-button"} text={"Learn More"} />
					</Link>
				</div>
			</div>
		</StyledServiceBubble>
	);
};

export default ServiceBubble;
