import styled from "styled-components";

export const StyledNoPage = styled.main`
	.no-page-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem 0;
		text-align: center;
		color: ${(props) => props.theme.footerText};
	}

	.no-page-link {
		color: ${(props) => props.theme.backToTopBackground};
		font-weight: 600;
	}
`;
