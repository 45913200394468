import ChairTherapy from "assets/services/chair-therapy.webp";
import UrinaryIncontinence from "assets/services/urinary-incontinence.webp";
import PostnatalRecovery from "assets/services/postnatal-recovery.webp";
import MenstrualPain from "assets/services/menstrual-pain.webp";
import Menopause from "assets/services/menopause.webp";
import LowLibido from "assets/services/low-libido.webp";
import IntimancyChallenges from "assets/services/intimacy-challenges.webp";
import Impotence from "assets/services/impotence.webp";
import ProstatePain from "assets/services/prostate-pain.webp";
import AboutMountain from "assets/services/about-mountain.webp";

const servicesChairConfig = {
	bgImage: ChairTherapy,
	whatIsChair: {
		title: "What is the Chair?",
		description: [
			"Pelvic chair therapy represents a groundbreaking approach to treating pelvic floor dysfunctions. By leveraging advanced technology, this non-invasive method stimulates and strengthens the pelvic floor muscles, offering relief from various conditions. It’s particularly beneficial for individuals suffering from urinary incontinence, postpartum recovery challenges, and even certain reproductive health issues.",
			"The therapy works by sending focused electromagnetic energy directly to the pelvic floor muscles. This energy causes the muscles to contract and relax in a manner that’s impossible to achieve through voluntary exercise alone. Over a series of treatments, these contractions strengthen the muscles, improve blood flow, and enhance neuromuscular control.",
		],
	},
	contraIndicators: {
		title: "Contra-indicators",
		description:
			"As the chair uses a magnet in the treatment, clients with the following symptoms will not be able to receive the treatment:",
		points: [
			"Pregnancy and menstruation",
			"Heart pacemakers and people with heart disorders",
			"Blood disorders (problems with blood clotting)",
			"Medicine pumps",
			"Metal implants, especially hip implants",
			"Malignant tumors",
			"Copper intrauterine birth control devices (IUDs) (Mirena is permissible - it is made of plastic)",
			'Metal "piercing" such as a clitoris ring or any genital jewelry made of metal.',
			"Epilepsy",
		],
	},
	benefits: {
		title: "Benefits",
		points: [
			"Non-Invasive: No surgery or internal device insertion is required and there is no downtime required afterward.",
			"Convenient: Sessions typically last around 30 minutes to an hour depending on if it is a consultation session so arrive at the clinic a few minutes early to complete any necessary paperwork.",
			"Effective: Many patients notice significant improvements after just a few sessions. The recommended treatment comprises of 6 weekly sessions but accommodations can be made.",
			"Comfortable: Patients remain fully clothed, It is advised to wear comfortable clothing and experience minimal discomfort during treatment.",
		],
	},
};

const servicesConfig = [
	{
		number: 1,
		title: "Urinary Incontinence",
		explaination: "Let’s face it, it’s no fun when you can’t keep it in.",
		color: "army-green",
		image: UrinaryIncontinence,
		imagePosition: "bottom",
		description: [
			"Let’s face it, it’s no fun when you can’t keep it in. For many of us, that’s what we live with. It occurs because of such factors as weakened pelvic floor muscles, or nerve damage. It could be that you get sudden urges to urinate, or that you ‘spring a leak’ during activities which increase abdominal pressure. ",
		],
		id: "urinary-incontinence",
		animation: "pulse-army",
	},
	{
		number: 2,
		title: "Postnatal Recovery & Prolapsed Uterus",
		explaination:
			"Your pelvic floor has just gone through the most incredible and traumatic process known to humankind and healing is a slow nurturing process.  ",
		color: "light-peach",
		image: PostnatalRecovery,
		imagePosition: "center",
		description: [
			"So you have just brought life into the world the last thing you are thinking about is yourself. Your pelvic floor has just gone through the most incredible and traumatic process known to humankind and healing is a slow nurturing process.",
			"Sometimes it’s so tough being a woman. We love who we are, but we’re less inclined to love some of the challenges which we’re forced to face up to.",
			"A prolapsed uterus is a condition in which the uterus descends, or slips, down into the vagina, often due to weakened pelvic floor muscles and/or ligaments. This can leave you feeling heavy in your pelvis, and it can lead to urinary incontinence and difficulty with bowel movements. It’s certainly not something that you’ll want to continue living with, unless you absolutely have to.",
		],
		id: "postnatal-recovery",
		animation: "pulse-peach",
	},
	{
		number: 3,
		title: "Severe Menstrual Pain",
		explaination:
			"If you’re one of the millions of women who experience severe menstrual pain, then you’ll be acutely aware of its impact on your quality of life. ",
		color: "light-tea ",
		image: MenstrualPain,
		imagePosition: "center",
		description: [
			"If you’re one of the millions of women who experience severe menstrual pain, then you’ll be acutely aware of its impact on your quality of life. Dysmenorrhea is the medical term for this debilitating condition. It’s quite possible, though, that you will be able to find ways to reduce, or minimize, your pain—without resorting to the traditional painkillers.",
		],
		id: "menstrual-pain",
		animation: "pulse-tea",
	},
	{
		number: 4,
		title: "Menopause",
		explaination:
			"We’re all familiar with the journey that we, as women, need to embrace as we move into our late 40s and 50s",
		color: "light-sky",
		image: Menopause,
		imagePosition: "center",
		description: [
			"We’re all familiar with the journey that we, as women, need to embrace as we move into our late 40s and 50s. Menopause leads to a natural decline in reproductive hormones, with all sorts of accompanying symptoms. Most of us live with what we’ve been landed with, without realising that there are many options out there to help minimize our discomfort.",
		],
		id: "menopause",
		animation: "pulse-sky",
	},
	{
		number: 5,
		title: "Low Libido",
		explaination:
			"A low libido means that, for any number of reasons, you’ve been left with a low sex drive. ",
		color: "burning-pink",
		image: LowLibido,
		imagePosition: "center",
		description: [
			"A low libido means that, for any number of reasons, you’ve been left with no desire to experience physical connection. A decrease in savoring intimacy is a common problem, believe it or not, and the good news is that, more often than not, it’s temporary.",
		],
		id: "low-libido",
		animation: "pulse-pink",
	},
	{
		number: 6,
		title: "Experiencing Physical Connection",
		explaination:
			"These are the kinds of things that we just don’t talk about. ",
		color: "blaze-green ",
		image: IntimancyChallenges,
		imagePosition: "center",
		description: [
			"These are the kinds of things that we just don’t talk about. The fact that we don’t want, or can’t enjoy pleasure, is a problem which we keep to ourselves. As miserable as these problems may be, we accept that we’ll need to live with them. But why live with this, unless there really is no other option?",
		],
		id: "experiencing-physical-connection",
		animation: "pulse-green",
	},
	{
		number: 7,
		title: "Male Intimacy Challenges",
		explaination: "No man wants to have to live with this.",
		color: "sun-orange",
		image: Impotence,
		imagePosition: "center",
		description: [
			"No man wants to have to live with this. Male intimacy challenges—when a man is unable to sufficiently get his act together to have intercourse—is either a physical or psychological problem, or a combination thereof. It causes stress, strains relationships, and results in low self-confidence. The vast majority of us who have this condition, suffer in silence.  ",
		],
		id: "male-intimacy-challenges",
		animation: "pulse-orange",
	},
	{
		number: 8,
		title: "Prostate Pain/Enlarged Prostate",
		explaination: "Being a man comes with its own challenges as we age.",
		color: "light-purple",
		image: ProstatePain,
		imagePosition: "center",
		description: [
			"Being a man comes with its own challenges as we age. Many of us experience the misery of prostatitis, a disorder of the prostate gland usually associated with inflammation. Prostatitis can make it difficult, or painful, to pee. It can also lead to pain in the groin, pelvic area and genitals.",
			"An enlarged prostate is normally painless, but it can put pressure on the urethra, and make it difficult to urinate. You could also find yourself needing to urinate more and more frequently. Mostly, there’s no need to live with this. There are many options available to you.",
		],
		id: "prostate-pain",
		animation: "pulse-purple",
	},
];

const servicesAboutConfig = {
	title: "Who We Are",
	color: "light-sand",
	description: [
		"We are sisters who are passionate about uplifting people and inspiring them to nurture and celebrate their own wellness. We want you to feel empowered and to commit to becoming your authentic, healthy self.",
		"After gaining perspective from battling our own health conditions, we realize how liberating wellness feels. From our own struggles, grew the desire to help others through their challenges. Everyone should get the opportunity to experience the relief of feeling well.",
		"There is always hope, and you are never alone. Find your freedom in wellness.",
	],
	image: AboutMountain,
};

export { servicesChairConfig, servicesConfig, servicesAboutConfig };
