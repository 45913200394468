import styled from "styled-components";

export const StyledServiceBubble = styled.div`
	.service-bubble {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		border-radius: 50%;
		width: 14rem;
		height: 14rem;
		animation: ${(props) => props.animation} 2.5s infinite;

		&:hover .explaination-container {
			clip-path: circle(100%);
		}

		&:active .explaination-container {
			clip-path: circle(100%);
		}

		.bubble-heading {
			display: flex;
			flex-direction: column;
			transition: all 0.5s ease-in-out;
			text-align: center;
			justify-content: center;
			align-items: center;
		}

		.explaination-container {
			clip-path: circle(0% at 50% 50%);
			transition: all 0.5s ease-in-out;
			position: absolute;
			max-width: 80%;
			min-height: 38%;
			border-radius: 25px;
			padding: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 1.5rem;
			box-shadow: 0px 0px 43.5px 2px #374e2d59;

			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-align: center;

			@media (min-width: 768px) {
				max-width: 35%;
				min-height: 36%;
			}

			@media (min-width: 1280px) {
				max-width: 15%;
				min-height: 38%;
			}

			.learn-more-button {
				font-size: 16px;
			}

			&:hover {
				clip-path: circle(100%);
			}
		}
	}

	.number {
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-align: center;

		@media (min-width: 768px) {
			max-width: 35%;
			min-height: 32%;
		}

		@media (min-width: 1280px) {
			max-width: 15%;
			min-height: 30%;
		}

		.learn-more-button {
			font-size: 16px;
		}

		&:hover {
			clip-path: circle(100%);
		}
	}

	.number {
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.title {
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: capitalize;
		width: 90%;
	}

	@-webkit-keyframes pulse-army {
		0% {
			box-shadow: 0 0 0 0 #adac99;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #adac9900;
		}
	}

	@keyframes pulse-army {
		0% {
			box-shadow: 0 0 0 0 #adac99;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #adac9900;
		}
	}

	@-webkit-keyframes pulse-peach {
		0% {
			box-shadow: 0 0 0 0 #eecaaf;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #eecaaf00;
		}
	}

	@keyframes pulse-peach {
		0% {
			box-shadow: 0 0 0 0 #eecaaf;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #eecaaf00;
		}
	}

	@-webkit-keyframes pulse-tea {
		0% {
			box-shadow: 0 0 0 0 #f0ffe8;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #f0ffe800;
		}
	}

	@keyframes pulse-tea {
		0% {
			box-shadow: 0 0 0 0 #f0ffe8;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #f0ffe800;
		}
	}

	@-webkit-keyframes pulse-sky {
		0% {
			box-shadow: 0 0 0 0 #b6d1d9;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #b6d1d900;
		}
	}

	@keyframes pulse-sky {
		0% {
			box-shadow: 0 0 0 0 #b6d1d9;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #b6d1d900;
		}
	}

	@-webkit-keyframes pulse-pink {
		0% {
			box-shadow: 0 0 0 0 #f29b9b;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #f29b9b00;
		}
	}

	@keyframes pulse-pink {
		0% {
			box-shadow: 0 0 0 0 #f29b9b;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #f29b9b00;
		}
	}

	@-webkit-keyframes pulse-green {
		0% {
			box-shadow: 0 0 0 0 #88a096;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #88a09600;
		}
	}

	@keyframes pulse-green {
		0% {
			box-shadow: 0 0 0 0 #88a096;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #88a09600;
		}
	}

	@-webkit-keyframes pulse-orange {
		0% {
			box-shadow: 0 0 0 0 #fdd29a;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #fdd29a00;
		}
	}

	@keyframes pulse-orange {
		0% {
			box-shadow: 0 0 0 0 #fdd29a;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #fdd29a00;
		}
	}

	@-webkit-keyframes pulse-purple {
		0% {
			box-shadow: 0 0 0 0 #c0b1b7;
		}

		100% {
			box-shadow: 0 0 0.25rem 1rem #c0b1b700;
		}
	}

	@keyframes pulse-purple {
		0% {
			box-shadow: 0 0 0 0 #c0b1b7;
		}

		100% {
			box-shadow: 0 0 0.25rem 1.5rem #c0b1b700;
		}
	}
`;
