import styled from "styled-components";

export const StyledServicesPage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 0 0 70px 0;

	.chair-background-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 70px;
	}

	.chair-container {
		display: flex;
		flex-direction: column;
		padding: 120px 27px;

		h1 {
			font-family: Antic Slab !important;
		}

		p,
		li {
			font-size: 17px;
			line-height: 28px;
			font-weight: 500;
		}

		li {
			margin-bottom: 0.25rem;
		}

		.what-is-chair {
			display: flex;
			flex-direction: column;
			margin-bottom: 40px;
			text-align: center;
			color: #43543c;
			font-style: normal;
			font-weight: 400;

			h1 {
				font-size: 36px;
				line-height: normal;
				text-transform: capitalize;
				margin-bottom: 38px;
			}

			p {
				&:first-of-type {
					margin-bottom: 22px;
				}
			}
		}

		.chair-info {
			display: flex;
			flex-direction: column;
			font-style: normal;
			font-weight: 400;

			h1 {
				font-size: 32px;
				line-height: 32px;
			}

			ul {
				margin-left: 27px;
			}

			.contra-indicators {
				margin-bottom: 40px;
				color: #17301c;

				display: flex;
				flex-direction: column;
				gap: 17px;
				width: 100%;
			}

			.benefits {
				color: #17301c;
				display: flex;
				flex-direction: column;
				gap: 25px;
				width: 100%;
			}
		}
	}

	.services-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 28px;
		max-width: 1140px;
		margin-bottom: 70px;
	}

	.about-container {
		max-width: 1140px;
		scroll-margin-top: 7rem;
	}

	@media (min-width: 768px) {
		margin: 0 0 67px 0;

		.chair-container {
			max-width: 695px;
		}

		.services-container {
			gap: 50px;
			margin-bottom: 67px;
		}
	}

	@media (min-width: 1280px) {
		.chair-container {
			max-width: 1100px;

			.chair-info {
				display: flex;
				flex-direction: row;
				gap: 40px;
			}
		}

		.services-container {
			gap: 15px;
			margin-bottom: 70px;
		}
	}

	@media screen and (min-width: 1440px) {
		.chair-container {
			max-width: 1140px;
		}
	}
`;
