import React, { useEffect, useState } from "react";
import { StyledNoPage } from "./styles";
import { Link } from "react-router-dom";
import { Container } from "components";

const NoPage = () => {
	const [menuHeight, setMenuHeight] = useState(0);

	useEffect(() => {
		const menu = document.querySelector(".navigation");

		if (menu) {
			setMenuHeight(menu.clientHeight);
		}
	}, []);

	return (
		<StyledNoPage style={{ marginTop: menuHeight }}>
			<Container>
				<div className="no-page-container">
					<h1 className="no-page-header">Page Not Found</h1>
					<p className="no-page-sub-header">
						Sorry, the page you are looking for does not exist.
					</p>
					<p>
						Click{" "}
						<Link to="/" className="no-page-link">
							here
						</Link>{" "}
						to go back to the home page.
					</p>
				</div>
			</Container>
		</StyledNoPage>
	);
};

export default NoPage;
