import * as React from "react";
import { createBrowserRouter } from "react-router-dom";

import App from "./App";
import { Home, ServicesPage, NoPage } from "pages";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "services",
				element: <ServicesPage />,
			},
			{
				path: "*",
				element: <NoPage />,
			},
		],
	},
]);
